html {
  box-sizing: border-box;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #111111;
  background: #141a25;
  line-height: 1.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  /*
   * HACK: This is a workaround for the react-list overflow issue that will most
   * likely have averse effects in the standalone version of this app.
   */
  overflow: hidden;
}

body.modal-blur-body > *:not(#modal-root) {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

a {
  text-decoration: none;
  color: currentColor;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  touch-action: manipulation;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1 {
  line-height: 4rem;
  font-size: 3rem;
  font-weight: 400;
}

h2 {
  line-height: 3rem;
  font-size: 2.25rem;
  font-weight: 400;
}

h3 {
  line-height: 2.25rem;
  font-size: 1.75rem;
  font-weight: 500;
}

h4 {
  line-height: 1.875rem;
  font-size: 1.375rem;
  font-weight: 500;
}

h5 {
  line-height: 1.5rem;
  font-size: 1.125rem;
  font-weight: 700;
}

h6 {
  line-height: 1.375rem;
  font-size: 1rem;
  font-weight: 700;
}

strong {
  font-weight: 700;
}

p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

label {
  color: #627585;
  color: rgb(98, 117, 133);
  margin-bottom: 0.5rem;
  touch-action: manipulation;
  line-height: 1.0625rem;
  font-size: 0.75rem;
  font-weight: 500;
}

